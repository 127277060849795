import dayjs from 'dayjs'
import {
  AppFilter,
  AppSort,
  ProjectLogRecord,
  ProjectRecord,
  ProjectStatus,
  TaskLogRecord,
  TaskStatusType,
  UserRecord,
  WorkLogRecord,
} from 'models'
import React from 'react'

export const formatYYYYMMDD = (date?: any): string => {
  if (date) {
    if (typeof date === 'string' && date.includes('0001-01-01')) {
      return ''
    }
    return dayjs(date).format('YYYY-MM-DD')
  }
  return ''
}

export const formatAPIDate = (date?: any): string => {
  if (date) return dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ')
  return ''
}

export const formatTime = (date: any): string => {
  return dayjs(date).format('HH:mm')
}

export const formatToCurrency = (value: number): string => {
  return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export const formatNumber = (value: number): string => {
  return value.toFixed(2)
}

export const formatTugrug = (value: number): string => {
  return `${formatToCurrency(Math.ceil(value * 100) / 100)}₮`
}

export const await05s = async (): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    setTimeout(() => resolve('ok'), 500)
  })
}

export const urlify = (text: string) => {
  var urlRegex = /(http?s?:\/\/[^\s]+)/g
  return text.replace(urlRegex, (url) => {
    return '<a href="' + url + '" target="_blank" rel="noreferrer">' + url + '</a>'
  })
}

export const cleanObject = (obj: any): Object => {
  if (obj instanceof Object) {
    let keys = Object.keys(obj)
    let cloned_obj = { ...obj }
    for (let key of keys) {
      if (!cloned_obj[key]) delete cloned_obj[key]
    }
    return cloned_obj
  }
  return {}
}

export const buildQuery = (sorter?: AppSort, data?: Object): AppFilter => {
  let filter: AppFilter = {}
  if (data) {
    filter.filter = data
  }
  if (sorter && sorter.order) {
    filter.sort = {
      field: sorter.field,
      order: sorter.order.includes('desc') ? 'desc' : 'asc',
    }
  }
  return filter
}

export const userToString = (user: UserRecord | null | undefined) =>
  user && `${user.last_name?.substr(0, 1)}. ${user.first_name}`

export const userToStringFnLn = (first_name: string, last_name: string) => `${last_name?.substr(0, 1)}. ${first_name}`

export const difficultyColorTag = (val: number) => {
  switch (val) {
    case 1:
      return 'volcano'
    case 2:
      return 'red'
    default:
      return 'green'
  }
}

export const taskStatusColorTag = (val: TaskStatusType) => {
  switch (val) {
    case TaskStatusType.not_started:
      return ''
    case TaskStatusType.on_going:
      return 'gold'
    case TaskStatusType.check:
      return 'purple'
    case TaskStatusType.on_hold:
      return 'geekblue'
    case TaskStatusType.done:
      return 'green'
    default:
      return 'green'
  }
}

export const extractDiffFromDates = (firstDate: Date, lastDate: Date) => {
  const monthDiff = lastDate.getMonth() - firstDate.getMonth() + 12 * (lastDate.getFullYear() - firstDate.getFullYear())
  const diffTime = lastDate.getTime() - firstDate.getTime()
  return {
    monthDiff,
    dayDiff: diffTime / (1000 * 60 * 60 * 24),
  }
}

export const getYearMonth = (date: Date) => {
  return `${date.getFullYear()} / ${date.getMonth() + 1}`
}

export const changeDateByMonth = (date: Date, num: number) => {
  return new Date(new Date(new Date().setMonth(date.getMonth() + num)).setDate(1))
}

export const overdueProjectChecker = (project: ProjectRecord) => {
  const isNoEnd = new Date(project.due_date).getFullYear() < 2000
  const isOverdue =
    project.status === ProjectStatus.Ongoing &&
    new Date().getTime() >= new Date(project.due_date).getTime() /* overdue */
  return isOverdue && !isNoEnd
}

/* log utils */
export const addTagToProjectLog = (projectLogs: ProjectLogRecord[]) => {
  return projectLogs.map((each) => ({ ...each, type: 'project' }))
}

export const addTagToWorkLog = (workLogs: WorkLogRecord[]) => {
  return workLogs.map((each) => ({ ...each, type: 'work' }))
}

export const addTagToTaskLog = (taskLogs: TaskLogRecord[]) => {
  return taskLogs.map((each) => ({ ...each, type: 'task' }))
}

import { Button, Tooltip } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { ReactNode } from 'react'

interface Props extends ButtonProps {
  icon: ReactNode
  tooltip?: string
}

const IconButton: React.FC<Props> = ({ tooltip, icon, ...props }) => {
  if (tooltip)
    return (
      <Tooltip title={tooltip} trigger={['hover']}>
        <Button icon={icon} {...props} className="flex items-center justify-center gap-2" />
      </Tooltip>
    )

  return <Button {...props} className="flex items-center justify-center gap-2 icon-space" />
}

export default IconButton

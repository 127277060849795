import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'

import SettingsReducer from './settings/reducers'
import UserReducer from './user/reducers'

const Reducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    user: UserReducer,
    settings: SettingsReducer,
  })
export default Reducer

interface Props {
  color?: string
}

const PoweredBy: React.FC<Props> = ({ color = 'white' }) => {
  return (
    <div
      className="powered-by"
      style={{
        color,
      }}
    >
      © Powered by&nbsp;
      <strong>
        <a href="https://fibo.cloud" target="_blank" rel="noreferrer">
          <span style={{ color: '#1DA57A' }}>FIBO CLOUD</span>
          {/* <span style={{ color: '#e52e26' }}>FIBO CLOUD</span> */}
        </a>
      </strong>
    </div>
  )
}

export default PoweredBy

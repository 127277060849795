import { message } from 'antd'
import axios from 'axios'
import { BaseRequestProps, BaseResponse } from 'models'
import * as qs from 'qs'

const apiConfig = {
  baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BACK_PROD_URL : process.env.REACT_APP_BACK_URL,
  headers: {
    common: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Headers': '*',
    },
  },
  paramsSerializer: (params: any) => {
    let tmp = qs.stringify(params, { indices: false, arrayFormat: 'comma', charset: 'utf-8' })
    return tmp
  },
}

const request = axios.create(apiConfig)
const catchError = (err: any) => {
  console.log(err.response)
  if (err.response) {
    if (err.response.status === 401) {
      localStorage.removeItem('token')
      window.location.href = '/'
    } else if (err.response.status === 403) {
      message.warning({
        content: 'Танд хандах эрх байхгүй байна.',
      })
    }
  } else if (err.message === 'Network Error') {
    message.info({
      content: 'Алдаа гарлаа. Дараа дахин оролдоно уу',
    })
  } else {
    message.error({
      content: err.message,
    })
  }
}

export const BaseRequest = async (config: BaseRequestProps) => {
  const token = localStorage.getItem('token')
  if (token) request.defaults.headers.common.Authorization = `Bearer ${token}`

  const locale = localStorage.getItem('app.settings.locale')
  request.defaults.headers.common['Multi-Language'] = locale || 'en'

  try {
    let responseInstance = await request({ ...config })
    const response = responseInstance.data as BaseResponse<any>
    if (response.status_code !== 0) {
      catchError(new Error(response.error_msg))
      return null
    }
    return response.body || false
  } catch (e) {
    catchError(e)
    return null
  }
}

// #region [Props]
export interface BaseProps {
  params?: any
  onError?: Function
}

export interface GetProps extends BaseProps {}

export interface MutateProps extends BaseProps {
  data: Object
}
// #endregion

import { ConnectedRouter } from 'connected-react-router'
import { history } from 'index'
import Layout from 'layout'
import { NotAuthorized, NotFound } from 'pages/errors'
import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

const LoadableLoader = (path: Function) =>
  React.lazy(() => new Promise((resolve, reject) => setTimeout(() => resolve(path()), 100)))

interface RouteInterface {
  path: string
  component: React.FC | React.ComponentClass
  exact: boolean
}

const routes: RouteInterface[] = [
  {
    path: '/leaderboard',
    component: LoadableLoader(() => import('pages/leaderboard')),
    exact: true,
  },
  {
    path: '/auth/signin',
    component: LoadableLoader(() => import('pages/auth/signin')),
    exact: true,
  },
  {
    path: '/auth/signup',
    component: LoadableLoader(() => import('pages/auth/signup')),
    exact: true,
  },
  {
    path: '/profile',
    component: LoadableLoader(() => import('pages/profile')),
    exact: true,
  },
  {
    path: '/admin/users',
    component: LoadableLoader(() => import('pages/admin/users')),
    exact: true,
  },
  {
    path: '/admin/teams',
    component: LoadableLoader(() => import('pages/admin/team')),
    exact: true,
  },
  {
    path: '/admin/projects',
    component: LoadableLoader(() => import('pages/admin/project')),
    exact: true,
  },
  {
    path: '/admin/intervals',
    component: LoadableLoader(() => import('pages/admin/interval')),
    exact: true,
  },
  {
    path: '/work/view-team-tasks',
    component: LoadableLoader(() => import('pages/work/team')),
    exact: true,
  },
  {
    path: '/work/dashboard',
    component: LoadableLoader(() => import('pages/work/dashboard')),
    exact: true,
  },
  {
    path: '/work/works',
    component: LoadableLoader(() => import('pages/work/work')),
    exact: true,
  },
  {
    path: '/work/tasks',
    component: LoadableLoader(() => import('pages/work/task')),
    exact: true,
  },
  {
    path: '/report/team',
    component: LoadableLoader(() => import('pages/report/team')),
    exact: true,
  },
  {
    path: '/report/project',
    component: LoadableLoader(() => import('pages/report/project')),
    exact: true,
  },
  {
    path: '/coffee-match',
    component: LoadableLoader(() => import('pages/coffee-match')),
    exact: true,
  },
  {
    path: '/coffee-match-teams',
    component: LoadableLoader(() => import('pages/coffee-match/parts/coffee-match-list')),
    exact: true,
  },
  {
    path: '/badges',
    component: LoadableLoader(() => import('pages/badges')),
    exact: true,
  },
  {
    path: '/badge-announcement',
    component: LoadableLoader(() => import('pages/badge-announcement')),
    exact: true,
  },
  {
    path: '/avatar',
    component: LoadableLoader(() => import('pages/avatars')),
    exact: true,
  },
]

const AppRouter = () => {
  // const user = useSelector<AppState, UserState>((state) => state.user)

  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Suspense fallback={<div className="w-fill h-fill" style={{ position: 'absolute' }}></div>}>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/work/dashboard" />} />
            {routes.map((route) => (
              <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />
            ))}
            <Route exact path="403" component={NotAuthorized} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Layout>
    </ConnectedRouter>
  )
}

export default AppRouter

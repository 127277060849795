import { Form } from 'antd'
import { FormProps } from 'antd/lib/form'

interface Props<T> extends FormProps<T> {}

export default function LayoutedForm<RecordType extends object = any>({ ...rest }: Props<RecordType>) {
  return (
    <Form<RecordType>
      {...rest}
      className={`${rest.className} form-label-left`}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    />
  )
}

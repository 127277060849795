import { Form, FormInstance, Table } from 'antd'
import { TableProps } from 'antd/lib/table'

export interface Props<T> extends TableProps<T> {
  form?: FormInstance
}

export default function DataTable<RecordType extends object = any>({ form, ...props }: Props<RecordType>) {
  let { columns } = props
  if (columns && columns.length > 0) {
    columns.unshift({
      title: '',
      key: 'index',
      width: 30,
      render: (text, record, index) => index + 1,
    })
  }
  const renderTable = <Table {...props} scroll={{ x: 'max-content' }} columns={columns} />
  if (form) return <Form form={form}>{renderTable}</Form>
  return renderTable
}

import { PoweredBy } from 'components'

interface Props {
  children: React.ReactNode
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="w-full h-screen bg-background">
      {children}
      <PoweredBy color="white" />
    </div>
  )
}

export default AuthLayout

import { LogoutOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar } from 'antd'
import { AppState, UserState } from 'models'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

interface Props {
  logout: () => void
}
const ProfileMenu: React.FC<Props> = ({ logout }) => {
  const currentUser = useSelector<AppState, UserState>((state) => state.user)

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/profile">
          <FormattedMessage id="profile" />
        </Link>
      </Menu.Item>
      <Menu.Item onClick={logout}>
        <LogoutOutlined /> <FormattedMessage id="logout" />
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
      <Avatar>
        {currentUser.first_name?.substr(0, 1).toLocaleUpperCase()}
        {currentUser.last_name?.substr(0, 1).toLocaleUpperCase()}
      </Avatar>
    </Dropdown>
  )
}

export default ProfileMenu

import { TagProps } from 'antd'
import { ColumnsType } from 'antd/lib/table/interface'
import { Method } from 'axios'
import { ReactNode } from 'react'

//#region [Redux]
export type LocaleTypes =
  | 'mn-MN' // Mongolia
  | 'en-US' // English

export interface UserState {
  id?: number
  email?: string
  first_name?: string
  last_name?: string
  nick_name?: string
  role?: UserRoleType
  last_login?: Date
  loading: boolean
  authorized: boolean
  is_active?: boolean
  is_project_workable: boolean
  team_id?: number
  team?: string
}
export interface SettingsState {
  isMobileView?: boolean
  isMobileMenuOpen?: boolean
  isLightTheme?: boolean
  isSettingsOpen?: boolean
  isMenuTop?: boolean
  isMenuCollapsed?: boolean
  isBorderless?: boolean
  isSquaredBorders?: boolean
  isFixedWidth?: boolean
  isMenuShadow?: boolean
  locale: LocaleTypes
  [x: string]: any
}
export interface AppState {
  user: UserState
  settings: SettingsState
}
export interface BaseAction<T> {
  type: string
  payload: T
}
//#endregion

//#region [Supporting Types]
export interface ColumnsTypeEditable<T> extends ColumnsType<T> {
  editable: boolean
}
//#endregion
//#region [API]
export interface BaseResponse<T> {
  status_code: number
  error_msg: string
  body: T | null
}

export interface BaseRequestProps {
  url: string
  method: Method
  params?: Object
  data?: Object
}

export type ORDER = 'asc' | 'desc'
export interface AppSort {
  field: string
  order: ORDER
}
export interface AppFilter {
  sort?: AppSort
  filter?: Object
}
interface Record {
  id: number
  created_date: Date
  modified_date: Date
}
//#endregion

//#region [PageLayout]
export interface ActionProp {
  name: string
  icon?: ReactNode
  disabled?: boolean
}
//#endregion

//#region [User API]
export interface RoleRef {
  [x: string]: TagProps
}

export type UserRoleType =
  | 'admin' // Admin user
  | 'team_leader' // Team leader user
  | 'user' // user

export interface UserRecord extends Record {
  email: string
  first_name: string
  last_name: string
  nick_name: string
  role: UserRoleType
  is_active: boolean
  last_login_date: Date
  team_id: number
  team?: TeamRecord
  password?: string
  confirm?: string
}

export interface UserReportRecord extends Record {
  project_count: number
  task_count: number
  work_count: number
  from_previous_task_count: number
  interval_performance: number
  last_interval_performance: number
}

export interface ProjectReportRecord extends Record {
  interval_progress: number
  last_interval_progress: number
}

export interface ProjectIntervalStatic extends ProjectReportRecord {
  personality: number
  project_count: number
  task_count: number
  work_count: number
  from_previous_task_count: number
}

export interface ProjectOverviewRecord {
  personalities: ProjectIntervalStatic[]
}

export interface SummeryTableInterface {
  key: string
  val: any
  is_prep?: boolean
}
export interface DoubleSTableInterface {
  key: string
  key1: string
  val: any
  val1: any
  is_prep?: boolean
  is_prep1?: boolean
}
export interface TeamRecord extends Record {
  name: string
  color: string
  leader_id: number
  leader?: UserRecord
  members?: UserRecord[]
}
//#endregion

//#region [Project Management]
export type PersonalityTypes =
  | 1 // batman
  | 2 // superman
  | 3 // joker
export type ProjectTypes =
  | 1 // development
  | 2 // consulting
  | 3 // business_temporary
  | 4 // business_permanent
  | 5 // operational

export type ProjectStatusTypes =
  | 1 // ongoing
  | 2 // On hold
  | 3 // done
  | 4 // cancelled
  | 5 // negotiating

export type ContractStatusTypes =
  | 1 // not_contracted
  | 2 // contracted_would
  | 3 // contracted_done

export enum Personalities {
  Batman = 1,
  Superman = 2,
  Joker = 3,
}

export enum ProjectType {
  Development = 1,
  Consulting = 2,
  'Business Temporary' = 3,
  'Business Permanent' = 4,
  Operational = 5,
}

export enum ProjectStatus {
  Ongoing = 1,
  'On hold' = 2,
  Done = 3,
  Cancelled = 4,
  Negotiating = 5,
  Overdue = 6,
}

export interface ProjectRecord extends Record {
  [x: string]: any
  name: string
  progress: number
  interval_progress: number
  owner_id: number
  owner?: UserRecord
  assistant_id: number
  assistant?: UserRecord
  members?: UserRecord[]
  works?: WorkRecord[]
  tasks?: TaskRecord[]
  personality: PersonalityTypes
  project_type: ProjectTypes
  status: ProjectStatusTypes
  contract_status: ContractStatusTypes
  due_date: Date
  start_date: Date
}

export interface RemarkAndDeliverableRecord extends Record {
  type: number
  user_id: number
  user?: UserRecord
  task_id: number
  description: string
}

export type ProjectCommitActions =
  | 1 // change_owner
  | 2 // change_assistant
  | 3 // change_info
  | 4 // time_interval_changed
  | 5 // progress_change

export interface ProjectCommitRecord extends Record {
  action: ProjectCommitActions
  message: string
  progress: number
  user_id: number
  user?: UserRecord
  project_id: number
  project?: ProjectRecord
  related_work_id: number
  related_work?: WorkRecord
}

export type DifficultyTypes =
  | 1 // low
  | 2 // med
  | 3 // high

export type WorkStatus =
  | 1 // not started
  | 2 // on going
  | 3 // check
  | 4 // on hold
  | 5 // done

export enum WorkStatusType {
  not_started = 1,
  on_going = 2,
  check = 3,
  on_hold = 4,
  done = 5,
}

export interface WorkRecord extends Record {
  name: string
  desc: string
  calc_progress: boolean
  progress: number
  human_time: number
  difficulty: DifficultyTypes
  status: WorkStatus
  owner_id: number
  owner?: UserRecord
  project_id: number
  project?: ProjectRecord
  tasks?: TaskRecord
  start_date: Date
  end_date: Date
}

export type WorkCommitActions =
  | 1 // change_progress
  | 2 // change_info

export interface WorkCommitRecord extends Record {
  action: WorkCommitActions
  message: string
  progress: number
  user_id: number
  user?: UserRecord
  work_id: number
  work?: WorkRecord
}

export interface IntervalRecord extends Record {
  name: string
  begin_date: Date
  end_date: Date
}

export interface SettingsRecord {
  settings_name: string
  enabled: boolean
  created_date: Date
  modified_date: Date
  value: string
}

export type TaskStatus =
  | 1 // not started
  | 2 // on going
  | 3 // check
  | 4 // on hold
  | 5 // done

export enum TaskStatusType {
  not_started = 1,
  on_going = 2,
  check = 3,
  on_hold = 4,
  done = 5,
}

export interface TaskRecord {
  id: number
  name: string
  desc: string
  remark: string
  deliverable: string
  completion: number
  difficulty: number
  status: TaskStatus
  allocated_time: number
  claimed_time: number
  parent_id: number
  owner_id: number
  owner?: UserRecord
  in_work: boolean
  inpa: boolean
  assignee_id: number
  assignee?: UserRecord
  work_id: number
  work?: WorkRecord
  project_id: number
  project?: ProjectRecord
  interval_id: number
  interval?: IntervalRecord
}

export interface TaskWorkViewRecord {
  id: number
  name: string
  project_name: string
  desc: string
  remark: string
  deliverable: string
  completion: number
  allocated_time: number
  claimed_time: number
  parent_id: number
  owner_id: number
  assignee_id: number
  in_work: boolean
  inpa: boolean
  work_id: number
  project_id: number
  interval_id: number
  difficulty: number
  status: TaskStatus
  work_name: string
  work_difficulty: number
  work_progress: number
  work_human_time: number
  work_total_allocated: number
  work_total_claim: number
  total_completion: number //interval progress
  assignee_first_name: string
  assignee_last_name: string
}

export interface TeamLeaderboardRecord {
  high_task_count: number
  last_interval_avg_performance: number
  last_interval_task: number
  low_task_count: number
  mid_task_count: number
  name: string
  team_avg_performance: number
  team_total_allocated_time: number
  team_total_claimed_time: number
  top_user_active: UserRecord
  top_user_active_id: number
  top_user_performance: UserRecord
  top_user_performance_id: number
  total_projects: number
  total_task_count: number
}

export interface UserLeaderBoardRecord {
  id: number
  first_name: string
  last_name: string
  avg_performance: number
  low_task_count: number
  mid_task_count: number
  high_task_count: number
  total_task_count: number
  total_claimed_time: number
  total_allocated_time: number
  updated_count: number
}

export enum ProjectStatusColors {
  Overdue = '#F87171', // bg-red-400
  Ongoing = '#FCD34D', // bg-yellow-300
  'On Hold' = '#818CF8', // bg-indigo-400
  Negotiating = '#60A5FA', // bg-blue-400
  Done = '#34D399', // bg-green-400
  Cancelled = '#9CA3AF', // bg-gray-400
}

export enum ProjectTypeColors {
  Batman = '#374151', // bg-gray-700
  Superman = '#F59E0B', // bg-yellow-500
  Joker = '#8B5CF6', // bg-purple-500
}

export interface TaskLogRecord {
  id: number
  modified_date: Date
  created_date: Date
  action: string
  key: string
  value: string
  user_id: number
  user?: UserRecord
  project_id: number
  project?: ProjectRecord
  work_id: number
  work?: WorkRecord
  task_id: number
  task?: TaskRecord
}

export interface WorkLogRecord {
  id: number
  modified_date: Date
  created_date: Date
  action: string
  key: string
  value: string
  user_id: number
  user?: UserRecord
  project_id: number
  project?: ProjectRecord
  work_id: number
  work?: WorkRecord
}

export interface ProjectLogRecord {
  id: number
  modified_date: Date
  created_date: Date
  action: string
  key: string
  value: string
  user_id: number
  user?: UserRecord
  project_id: number
  project?: ProjectRecord
}

export interface AllLog {
  work_logs: WorkLogRecord[]
  task_logs: TaskLogRecord[]
  project_logs: ProjectLogRecord[]
}

export interface ICoffeeMatchCreateForm {
  team_ids: number[]
  coffee_match_frequency: string
  coffee_match_start_day: number
  coffee_match_skip_last_selected_person: boolean
  coffee_match_dont_match_selected_pairs: boolean
  coffee_match_dont_match_members_of_the_same_team: boolean
}

export interface ICoffeeMatchTeam {
  id: number
  modified_date: Date
  created_date: Date
  unique_id: number
  team_id: number
  team: TeamRecord
}

export interface ICoffeeMatch {
  id: number
  modified_date: Date
  created_date: Date
  coffee_match_team_unique_id: number
  person1_id: number
  person1: UserRecord
  person2_id: number
  person2: UserRecord
  is_met: number
}

export interface ICoffeeMatchTeamSettings {
  id: number
  modified_date: Date
  created_date: Date
  coffee_match_team_id: number
  coffee_match_frequency: string
  coffee_match_start_day: number
  coffee_match_skip_last_selected_person: boolean
  coffee_match_dont_match_selected_pairs: boolean
  coffee_match_dont_match_members_of_the_same_team: boolean
  is_disabled: boolean
  is_limit_reached: boolean
}
/* 

Field name   | Mandatory? | Allowed values  | Allowed special characters
----------   | ---------- | --------------  | --------------------------
Seconds      | Yes        | 0-59            | * / , -
Minutes      | Yes        | 0-59            | * / , -
Hours        | Yes        | 0-23            | * / , -
Day of month | Yes        | 1-31            | * / , - ?
Month        | Yes        | 1-12 or JAN-DEC | * / , -
Day of week  | Yes        | 0-6 or SUN-SAT  | * / , - ?

*/
export enum ECoffeeMatchFrequency {
  daily = '0 0 10 * * 1,2,3,4,5' /* everyday at 10am */,
  weekly = '0 0 10 * * 1' /* every monday at 10am */,
  monthly = '0 0 10 1 * *' /* every first day of the month at 10am */,
}

export enum EWeekDay {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}
export interface BadgeRecord {
  id: number
  modified_date: Date
  created_date: Date
  name: string
  description: string
  icon_url: string
  official: boolean
  default: boolean
  users?: UserRecord[]
}
export interface ICreateBadge {
  icon_url: string
  name: string
  description: string
  official: boolean
  default: boolean
}

export interface IGiveBadge {
  id: number
  anonymous: boolean
  user_id: number
  badge_id: number
  description: string
}

export interface IUploadResponse {
  id: number
  modified_date: Date
  created_date: string
  original_name: string
  file_name: string
  extention: string
  physical_path: string
  file_size: number
}

export interface AvatarRecord {
  category: string
  name: string
  icon_url: string
}

/* 
generate interface from json below
{
        "settings_name": "badge_limit",
        "value": "2",
        "enabled": false,
        "created_date": "0001-01-01T07:07:32+07:07",
        "modified_date": "0001-01-01T07:07:32+07:07"
    }

*/
export interface IBadgeLimitSettings {
  setttings_name: string
  value: string
  enabled: boolean
  created_date: Date
  modified_date: Date
}

//#endregion

import { Space } from 'antd'
import { ActionProp } from 'models'
import { useIntl } from 'react-intl'

import IconBtn from '../icon_btn'

interface Props {
  actions: ActionProp[]
  onAction: Function
}

const ActionRender: React.FC<Props> = ({ actions = [], onAction }) => {
  const intl = useIntl()

  return (
    <Space>
      {actions.map((action) => (
        <IconBtn
          key={action.name}
          icon={action.icon}
          tooltip={intl.formatMessage({ id: action.name })}
          onClick={() => onAction(action.name)}
          danger={action.name === 'delete'}
          size="small"
        />
      ))}
    </Space>
  )
}

export default ActionRender

import { Layout } from 'antd'
import { PoweredBy } from 'components'
import { AppState, SettingsState } from 'models'
import { useSelector } from 'react-redux'

import PageMenu from './page_menu'
import './styles.scoped.scss'
import TopHeader from './top_header'

const { Footer, Content } = Layout
interface Props {
  children: React.ReactNode
}

const MainLayout: React.FC<Props> = ({ children }) => {
  const settings = useSelector<AppState, SettingsState>((state) => state.settings)

  return (
    <Layout className="layout">
      <PageMenu />
      <Layout>
        <TopHeader settings={settings} />
        <Content style={{ padding: '16px 16px', minWidth: '280px' }}>{children}</Content>
        <Footer className="footer">
          <PoweredBy color="black" />
        </Footer>
      </Layout>
    </Layout>
  )
}

export default MainLayout

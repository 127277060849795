import { Col, Row } from 'antd'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { IntervalRecord } from 'models'
import { useEffect, useState } from 'react'
import { formatYYYYMMDD } from 'utils'

import './styles.scoped.scss'

dayjs.extend(isBetween)

interface Props {
  interval: IntervalRecord
  isSelected?: boolean
  onClick?: (data: IntervalRecord) => void
}

type TimeString = 'present' | 'past' | 'future'

const TaskInterval: React.FC<Props> = ({ interval, isSelected = false, onClick }) => {
  const [state, setState] = useState<IntervalRecord>(interval)
  const [time, setTime] = useState<TimeString>('past')

  useEffect(() => {
    let now = dayjs()
    let start = dayjs(interval.begin_date)
    let end = dayjs(interval.end_date)

    if (now.isBetween(start, end, null, '[]')) {
      setTime('present')
    } else if (now.isAfter(end)) {
      setTime('past')
    } else {
      setTime('future')
    }

    setState(interval)
  }, [interval])

  return (
    <div
      className={`wi-item ${isSelected && 'active'}`}
      role="button"
      style={{
        backgroundColor:
          time === 'present' ? 'rgba(32, 165, 122, 0.74)' : time === 'past' ? 'rgba(96, 175, 255)' : 'rgb(255, 82, 0)',
      }}
    >
      <Row
        onClick={() => {
          if (onClick) onClick(state)
        }}
      >
        <Col span={12}>Эхлэх:</Col>
        <Col span={12}>{formatYYYYMMDD(state.begin_date)}</Col>
        <Col span={12}>Дуусах:</Col>
        <Col span={12}>{formatYYYYMMDD(state.end_date)}</Col>
      </Row>
    </div>
  )
}

export default TaskInterval

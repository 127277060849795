import { UserState } from 'models'
import { Reducer, Action } from 'redux'

export enum Actions {
  SET_STATE = 'user/SET_STATE',
  LOGIN = 'user/LOGIN',
  LOGOUT = 'user/LOGOUT',
  GET_AUTH_USER = 'user/GET_AUTH_USER',
}

export interface DispatchAction extends Action {
  payload: Partial<UserState>
}

const initialState: UserState = {
  loading: false,
  authorized: false,
  is_project_workable: false,
}

const UserReducer: Reducer<UserState, DispatchAction> = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default UserReducer

import {
  ContractStatusTypes,
  PersonalityTypes,
  ProjectStatusTypes,
  ProjectTypes,
  RoleRef,
  DifficultyTypes,
} from 'models'

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const roleTypes = {
  admin: { color: 'magenta' },
  team_leader: { color: 'purple' },
  user: { color: 'green' },
} as RoleRef

export const commonActions = {
  create: 'create',
  edit: 'edit',
  delete: 'delete',
}

export const userTableActions = {
  setPassword: 'set_password',
  changeRole: 'change_role',
  changeTeam: 'change_team',
}

export const teamTableActions = {
  changeTeamLeader: 'change_leader',
}

export const projectTableActions = {
  commitHistory: 'commit_history',
  changeOwner: 'change_owner',
  changeAssistant: 'change_assistant',
  addMember: 'add_member',
}

export const workTableActions = {
  commitHistory: 'commit_history',
}

export const taskTableActions = {
  history: 'history',
  remarkAndDelivery: 'remark_and_deliverable',
}

export const tagColors = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple',
]

export const projectPersonalities = [
  1, // batman
  2, // superman
  3, // joker
] as PersonalityTypes[]

export const projectTypes = [
  1, // development
  2, // consulting
  3, // business_temporary
  4, // buiness_permanent
  5, // operational
] as ProjectTypes[]

export const dueDatedProjectTypes = [
  1, // development
  2, // consulting
  3, // business_temporary
] as ProjectTypes[]

export const projectStatus = [
  1, // ongoing
  2, // on hold
  3, // done
  4, // cancelled
  5, // negotiating
] as ProjectStatusTypes[]

export const projectContractStatus = [
  1, // not_contracted
  2, // contracted_would
  3, // contracted_done
] as ContractStatusTypes[]

export const workDifficulties = [
  1, // low
  2, // mid
  3, // high
] as DifficultyTypes[]

import { BulbOutlined, ClearOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons'
import { PageHeader, Dropdown, Button, Menu, Space } from 'antd'
import { TagType } from 'antd/lib/tag'
import { IconBtn, Loading } from 'components'
import { ActionProp } from 'models'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl'
import { await05s } from 'utils'

import './styles.scoped.scss'

const renderActions = (actions: ActionProp[], onAction?: (name: string) => void) => {
  if (actions.length > 0) {
    return (
      <Dropdown
        overlay={
          <Menu>
            {actions.map((action) => {
              return (
                <Menu.Item
                  key={action.name}
                  icon={action.icon}
                  disabled={action.disabled}
                  onClick={(e) => {
                    if (onAction) onAction(e.key as string)
                  }}
                >
                  <FormattedMessage id={action.name} />
                </Menu.Item>
              )
            })}
          </Menu>
        }
        placement="bottomLeft"
        trigger={['click']}
      >
        <Button icon={<BulbOutlined className="icon-space" />} type="primary">
          <FormattedMessage id="actions" />
        </Button>
      </Dropdown>
    )
  } else {
    return null
  }
}

interface Props {
  className?: string
  title: string
  children: ReactNode
  createText?: string
  tags?: React.ReactElement<TagType>
  loading?: boolean
  extra?: ReactNode
  actions?: ActionProp[]
  onlyTitle?: boolean
  onRefresh?: Function
  onAction?: (name: string) => void
  onCreate?: () => void
  onClear?: () => void
}

const PageLayout: React.FC<Props> = ({
  className,
  title,
  children,
  extra,
  loading = false,
  onlyTitle = false,
  createText,
  tags = [],
  actions = [],
  onAction,
  onCreate,
  onClear,
  onRefresh,
}) => {
  const intl = useIntl()
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const mounted = useRef<boolean>(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  return (
    <>
      <Helmet title={title || 'Fibo'} />
      <div className="bg-white w-full rounded-xl h-full" style={{ marginTop: onlyTitle ? 12 : 0 }}>
        {onlyTitle ? (
          <div className="ml-6 transform -translate-y-1/2">
            <span className="text-xl font-bold text-white bg-gray-900 px-4 py-0.5 rounded shadow-2xl">{title}</span>
          </div>
        ) : (
          <PageHeader
            className="rounded-xl"
            title={title}
            subTitle={
              <Space align="center">
                {!!onCreate && (
                  <IconBtn icon={<PlusOutlined />} type="primary" onClick={() => onCreate()} size="small">
                    {createText ? createText : intl.formatMessage({ id: 'create' })}
                  </IconBtn>
                )}
                {!!onClear && (
                  <IconBtn icon={<ClearOutlined />} onClick={() => onClear()} size="small">
                    {intl.formatMessage({ id: 'clear_search' })}
                  </IconBtn>
                )}
                {!!onRefresh && (
                  <Button
                    icon={<RedoOutlined spin={refreshing} />}
                    size="small"
                    onClick={async () => {
                      setRefreshing(true)
                      await onRefresh()
                      await05s().then(() => {
                        if (mounted.current) {
                          setRefreshing(false)
                        }
                      })
                    }}
                  />
                )}
                {extra}
                {renderActions(actions, onAction)}
              </Space>
            }
            tags={tags}
          />
        )}

        <div className={`content ${className || ''}`} style={{ paddingTop: onlyTitle ? 24 : 0 }}>
          {loading ? <Loading /> : children}
        </div>
      </div>
    </>
  )
}

export default PageLayout

import { PaginatedParams } from 'ahooks/lib/useAntdTable'
import axios from 'axios'
import {
  AppSort,
  AvatarRecord,
  BadgeRecord,
  IBadgeLimitSettings,
  ICoffeeMatch,
  ICoffeeMatchCreateForm,
  ICoffeeMatchTeam,
  ICoffeeMatchTeamSettings,
  ICreateBadge,
  IGiveBadge,
  IUploadResponse,
  SettingsRecord,
  TeamRecord,
} from 'models'
import { buildQuery } from 'utils'

import { BaseRequest } from './request'

export const fetchAxios = axios.create({})

//#region [Auth API]
export const loginAPI = async (data: Object) => {
  let res = await BaseRequest({ url: `auth/login`, method: 'post', data })
  return res
}
export const logoutAPI = async () => {
  let res = await BaseRequest({ url: `auth/logout`, method: 'get' })
  return res
}
//#endregion

//#region [User API]
export const getCurrentUser = () => {
  return BaseRequest({ url: `user/me`, method: 'get' })
}

export const getUserList = async (params: PaginatedParams[0], data: Object) => {
  const filter = buildQuery(params.sorter, data)
  const res = await BaseRequest({ url: `user/list`, method: 'post', data: filter })
  return { list: res || [], total: res?.length || 0 }
}

export const getUserSmallList = async (sorter: AppSort, data: Object) => {
  const filter = buildQuery(sorter, data)
  const res = await BaseRequest({ url: `user/slist`, method: 'post', data: filter })
  return res
}

export const singleUser = async (id: number | string) => {
  const res = await BaseRequest({ url: `user/get/${id}`, method: 'get' })
  return res
}

export const createUser = async (data: Object) => {
  const res = await BaseRequest({ url: `user`, method: 'post', data })
  return res
}

export const changeAuthUserPassword = async (data: Object) => {
  const res = await BaseRequest({ url: `user/change-password`, method: 'post', data })
  return res
}

export const updateUser = async (id: number | string, data: Object) => {
  const res = await BaseRequest({ url: `user/${id}`, method: 'put', data })
  return res
}

export const deleteUser = async (ids: number[]) => {
  const res = await BaseRequest({ url: `user`, method: 'delete', data: { ids } })
  return res
}
//#endregion

//#region [Team API]
export const getTeamList = async (params: PaginatedParams[0], data: Object) => {
  const filter = buildQuery(params.sorter, data)
  const res = await BaseRequest({ url: `team/list`, method: 'post', data: filter })
  return { list: res || [], total: res?.length || 0 }
}

export const getTeamListForReq = async (sorter: AppSort, data: Object) => {
  const filter = buildQuery(sorter, data)
  const res = await BaseRequest({ url: 'team/list', method: 'post', data: filter })
  return res
}

export const getTeamSmallList = async (sorter: AppSort, data: Object) => {
  const filter = buildQuery(sorter, data)
  const res = await BaseRequest({ url: 'team/slist', method: 'post', data: filter })
  return res
}

export const singleTeam = async (id: number | string) => {
  const res = await BaseRequest({ url: `team/get/${id}`, method: 'get' })
  return res
}

export const createTeam = async (data: Object) => {
  const res = await BaseRequest({ url: `team`, method: 'post', data })
  return res
}

export const updateTeam = async (id: number | string, data: Object) => {
  const res = await BaseRequest({ url: `team/${id}`, method: 'put', data })
  return res
}

export const deleteTeam = async (ids: number[]) => {
  const res = await BaseRequest({ url: `team`, method: 'delete', data: { ids } })
  return res
}
//#endregion

//#region [Project API]
export const getProjectList = async (params: PaginatedParams[0], data: Object) => {
  const filter = buildQuery(params.sorter, data)
  const res = await BaseRequest({ url: `project/list`, method: 'post', data: filter })
  return { list: res || [], total: res?.length || 0 }
}

export const getProjectSmallList = async (sorter: AppSort, data: Object) => {
  const filter = buildQuery(sorter, data)
  const res = await BaseRequest({ url: 'project/slist', method: 'post', data: filter })
  return res
}

export const singleProject = async (id: number | string) => {
  const res = await BaseRequest({ url: `project/get/${id}`, method: 'get' })
  return res
}

export const getProjectByUserID = async (id: number | string, sorter: AppSort, data: Object) => {
  const filter = buildQuery(sorter, data)
  const res = await BaseRequest({ url: `project/user/${id}`, method: 'post', data: filter })
  return res
}

export const historyProject = async (id: number | string) => {
  const res = await BaseRequest({ url: `project/history/${id}`, method: 'get' })
  return res
}

export const historyProjectAll = async () => {
  const res = await BaseRequest({ url: `project/history`, method: 'get' })
  return res
}

export const createProject = async (data: Object) => {
  const res = await BaseRequest({ url: `project`, method: 'post', data })
  return res
}

export const updateProject = async (id: number | string, data: Object) => {
  const res = await BaseRequest({ url: `project/${id}`, method: 'put', data })
  return res
}

export const deleteProject = async (ids: number[]) => {
  const res = await BaseRequest({ url: `project`, method: 'delete', data: { ids } })
  return res
}
//#endregion

//#region [Work API]
export const getWorkList = async (params: PaginatedParams[0], data: Object) => {
  const filter = buildQuery(params.sorter, data)
  const res = await BaseRequest({ url: `work/list`, method: 'post', data: filter })
  return { list: res || [], total: res?.length || 0 }
}

export const getWorkSmallList = async (sorter: AppSort, data: Object) => {
  const filter = buildQuery(sorter, data)
  const res = await BaseRequest({ url: 'work/slist', method: 'post', data: filter })
  return res
}

export const singleWork = async (id: number | string) => {
  const res = await BaseRequest({ url: `work/get/${id}`, method: 'get' })
  return res
}

export const historyWork = async (id: number | string) => {
  const res = await BaseRequest({ url: `work/history/${id}`, method: 'get' })
  return res
}

export const createWork = async (data: Object) => {
  const res = await BaseRequest({ url: `work`, method: 'post', data })
  return res
}

export const updateWork = async (id: number | string, data: Object) => {
  const res = await BaseRequest({ url: `work/${id}`, method: 'put', data })
  return res
}

export const deleteWork = async (ids: number[]) => {
  const res = await BaseRequest({ url: `work`, method: 'delete', data: { ids } })
  return res
}
//#endregion

//#region [Task API]
export const getTaskWorkView = async (id: number | string, project_id: number | string) => {
  const res = await BaseRequest({ url: `task/wlist/${id}?project_id=${project_id}`, method: 'get' })
  return res || []
}

export const getTaskPersonView = async (id: number | string, assignee_id: number | string) => {
  const res = await BaseRequest({ url: `task/plist/${id}?assignee_id=${assignee_id}`, method: 'get' })
  return res || []
}

export const getTaskList = async (params: PaginatedParams[0], data: Object) => {
  const filter = buildQuery(params.sorter, data)
  const res = await BaseRequest({ url: `task/list`, method: 'post', data: filter })
  return { list: res || [], total: res?.length || 0 }
}

export const getTaskSmallList = async (sorter: AppSort, data: Object) => {
  const filter = buildQuery(sorter, data)
  const res = await BaseRequest({ url: 'task/slist', method: 'post', data: filter })
  return res
}

export const singleTask = async (id: number | string) => {
  const res = await BaseRequest({ url: `task/get/${id}`, method: 'get' })
  return res
}

export const historyTask = async (parent_id: number | string) => {
  const res = await BaseRequest({ url: `task/history/${parent_id}`, method: 'get' })
  return res
}

export const createTaskRemarkAndDeliverable = async (data: Object) => {
  const res = await BaseRequest({ url: `task/remark-and-deliverable`, method: 'post', data })
  return res
}

export const getTaskRemarkAndDeliverable = async (taskID: string | number) => {
  const res = await BaseRequest({ url: `task/remark-and-deliverable/${taskID}`, method: 'get' })
  return res
}

export const createTask = async (data: Object) => {
  const res = await BaseRequest({ url: `task`, method: 'post', data })
  return res
}

export const updateTask = async (id: number | string, data: Object) => {
  const res = await BaseRequest({ url: `task/${id}`, method: 'put', data })
  return res
}

export const deleteTask = async (ids: number[]) => {
  const res = await BaseRequest({ url: `task`, method: 'delete', data: { ids } })
  return res
}
export const getOldTaskList = async (data: Object) => {
  const filter = buildQuery(
    {
      field: 'name',
      order: 'asc',
    } as AppSort,
    data,
  )
  const res = await BaseRequest({ url: 'task/past', method: 'post', data: filter })
  return res
}
//#endregion

//#region [Interval API]
export const getIntervalList = async (params: PaginatedParams[0], data: Object) => {
  const filter = buildQuery(params.sorter, data)
  const res = await BaseRequest({ url: `interval/slist`, method: 'post', data: filter })
  return { list: res || [], total: res?.length || 0 }
}

export const getIntervalSmallList = async (sorter: AppSort, data: Object) => {
  const filter = buildQuery(sorter, data)
  const res = await BaseRequest({ url: 'interval/slist', method: 'post', data: filter })
  return res
}

export const currentInterval = async () => {
  const res = await BaseRequest({ url: `interval/current`, method: 'get' })
  return res
}

export const reportInterval = async () => {
  const res = await BaseRequest({ url: `interval/report`, method: 'get' })
  return res
}

export const singleInterval = async (id: number | string) => {
  const res = await BaseRequest({ url: `interval/get/${id}`, method: 'get' })
  return res
}

export const createInterval = async (data: Object) => {
  const res = await BaseRequest({ url: `interval`, method: 'post', data })
  return res
}

export const updateInterval = async (id: number | string, data: Object) => {
  const res = await BaseRequest({ url: `interval/${id}`, method: 'put', data })
  return res
}

export const deleteInterval = async (ids: number[]) => {
  const res = await BaseRequest({ url: `interval`, method: 'delete', data: { ids } })
  return res
}

export const reportIntervalUser = async (interval_id: number | string, user_id: number | string) => {
  const res = await BaseRequest({ url: `interval/user-report/${interval_id}/${user_id}`, method: 'get' })
  return res
}

export const reportIntervalTeam = async (interval_id: number | string, team_id: number | string) => {
  const res = await BaseRequest({ url: `interval/team-report/${interval_id}/${team_id}`, method: 'get' })
  return res
}

export const reportIntervalProject = async (interval_id: number | string, project_id: number | string) => {
  const res = await BaseRequest({ url: `interval/project-report/${interval_id}/${project_id}`, method: 'get' })
  return res
}

export const reportIntervalProjectOverview = async (interval_id: number | string) => {
  const res = await BaseRequest({ url: `interval/project-report-overview/${interval_id}`, method: 'get' })
  return res
}

export const getIntervalSettings = async () => {
  const res = await BaseRequest({ url: `interval/settings`, method: 'get' })
  return res
}

export const updateIntervalSettings = async (data: { enabled: boolean }) => {
  const res = await BaseRequest({ url: `interval/settings`, data, method: 'post' })
  return res
}
//#endregion

//#region [Leaderboard]
export const leaderboardTeams = async () => {
  const res = await BaseRequest({ url: `interval/team/current`, method: 'get' })
  return res
}

export const leaderboardUsers = async (params: PaginatedParams[0], data: Object) => {
  const filter = buildQuery(params.sorter, data)
  const res = await BaseRequest({ url: 'interval/user/current', method: 'post', data: filter })
  return { list: res || [], total: res?.length || 0 }
}

//#endregion

//#region [Logs]
export const listAllLogs = async () => {
  const res = await BaseRequest({ url: 'log/list', method: 'get' })
  return res
}

export const listProjectLogs = async (projectId: number) => {
  const res = await BaseRequest({ url: `log/project-list/${projectId}`, method: 'get' })
  return res
}

export const listWorkLogs = async (workId: number) => {
  const res = await BaseRequest({ url: `log/work-list/${workId}`, method: 'get' })
  return res
}

export const listTaskLogs = async (taskId: number) => {
  const res = await BaseRequest({ url: `log/task-list/${taskId}`, method: 'get' })
  return res
}
//#endregion

//#region [CoffeeMatch]

export const listCoffeeMatchTeam = async () => {
  const res = await BaseRequest({ url: `coffee-match/team-matches/list`, method: 'get' })
  return res as ICoffeeMatchTeam[]
}

export const deleteCoffeeMatchTeam = async (data: { unique_id: number }) => {
  const res = await BaseRequest({ url: `coffee-match/team-matches/delete`, method: 'delete', data })
  return res
}

export const listCoffeeMatch = async (uniqueKey: number) => {
  const res = await BaseRequest({ url: `/coffee-match/list/${uniqueKey}`, method: 'get' })
  return res as ICoffeeMatch[]
}

export const getCoffeeMatchTeamSettings = async (uniqueKey: number) => {
  const res = await BaseRequest({ url: `/coffee-match/settings/${uniqueKey}`, method: 'get' })
  return res as ICoffeeMatchTeamSettings
}

export const getCoffeeMatchTeams = async (uniqueKey: number) => {
  const res = await BaseRequest({ url: `/coffee-match/get-teams/${uniqueKey}`, method: 'get' })
  return res as TeamRecord[]
}

export const createCoffeeMatch = async (data: ICoffeeMatchCreateForm) => {
  const res = await BaseRequest({ url: `/coffee-match/team-matches/create`, method: 'post', data })
  return res
}

//#endregion

//#region [Badges]

export const listBadges = async (params: PaginatedParams[0], data: Object) => {
  const filter = buildQuery(params.sorter, data)
  console.log({ filter })
  const res = await BaseRequest({ url: `badge/list`, method: 'post', data: filter })
  return { list: (res || []) as BadgeRecord[], total: res?.length || 0 }
}

export const giveBadge = async (data: IGiveBadge) => {
  const res = await BaseRequest({ url: `/badge/give-badge`, method: 'post', data })
  return res as IGiveBadge
}

export const getUserBadges = async (userId: number) => {
  const res = await BaseRequest({ url: `/badge/user-badges/${userId}`, method: 'get' })
  return res as BadgeRecord[]
}

export const createBadge = async (data: ICreateBadge) => {
  const res = await BaseRequest({ url: `/badge/create`, method: 'post', data })
  return res as BadgeRecord
}

export const updateBadge = async (id: number, data: Partial<ICreateBadge>) => {
  const res = await BaseRequest({ url: `/badge/update/${id}`, method: 'put', data })
  return res as BadgeRecord
}

export const deleteBadge = async (data: { id: number }) => {
  const res = await BaseRequest({ url: `/badge/delete`, method: 'delete', data })
  return res
}

export const uploadImage = async (file: File) => {
  const formData = new FormData()
  formData.append('images[]', file)
  const res = await axios.post(`${process.env.REACT_APP_FILE_UPLOAD_URL}upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return res.data.body as IUploadResponse[]
}

export const listAvatars = async () => {
  const res = await BaseRequest({ url: `avatar/list`, method: 'get' })
  return res as AvatarRecord[]
}

export const createAvatar = async (data: AvatarRecord) => {
  const res = await BaseRequest({ url: `avatar/create`, method: 'post', data })
  return res as AvatarRecord
}

export const deleteAvatar = async (data: { name: string }) => {
  const res = await BaseRequest({ url: `avatar/delete`, method: 'delete', data })
  return res as any
}

export const getBadgeLimitSettings = async () => {
  const res = await BaseRequest({ url: `badge/badge-limit-settings`, method: 'get' })
  return res as IBadgeLimitSettings | null
}

export const createOrUpdateBadgeLimitSettings = async (data: Pick<IBadgeLimitSettings, 'enabled' | 'value'>) => {
  const res = await BaseRequest({ url: `badge/badge-limit-settings`, method: 'post', data })
  return res as IBadgeLimitSettings
}

export const getTaskNotification = async () => {
  const res = await BaseRequest({ url: `task/task-notify-settings`, method: 'get' })
  return res as SettingsRecord[]
}

export const updateTaskNotification = async (data: {
  enabled: boolean
  weekly_underwork_reminder: boolean
  notify_team_leader: boolean
}) => {
  const res = await BaseRequest({ url: `task/task-notify-settings`, method: 'post', data })
  return res as SettingsRecord
}

export const getSettings = async (data: { settings_name: string }) => {
  const res = await BaseRequest({ url: `settings/get`, method: 'post', data })
  return res as SettingsRecord
}

export const updateSettings = async (data: { settings_name: string; value: string; enabled: boolean }) => {
  const res = await BaseRequest({ url: `settings/update`, method: 'put', data })
  return res as SettingsRecord
}

//#endregion

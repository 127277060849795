import { Form } from 'antd'
import { EditableContext } from 'hooks'
import React from 'react'

import '../styles.scoped.scss'

interface Props {
  index: number
}

const EditableRow: React.FC<Props> = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

export default EditableRow

import { Table, Tag } from 'antd'
import { SummeryTableInterface } from 'models'
import { formatNumber } from 'utils'

interface Props {
  data?: SummeryTableInterface[]
  loading?: boolean
}

const SummaryTable: React.FC<Props> = ({ data = [], loading = false }) => {
  return (
    <Table
      rowKey="key"
      loading={loading}
      components={{
        header: {
          row: () => null,
        },
      }}
      columns={[
        {
          dataIndex: 'key',
          width: '50%',
        },
        {
          dataIndex: 'val',
          width: '50%',
          render: (val, record) => (record.is_prep && val ? <Tag color="green">{formatNumber(val)}%</Tag> : val),
        },
      ]}
      dataSource={data}
      pagination={false}
      style={{ marginBottom: '16px' }}
    />
  )
}
export default SummaryTable

import { CloseOutlined } from '@ant-design/icons'
import { Button, Drawer } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import Loading from 'components/loading'
import { useWindow } from 'hooks'
import { FormattedMessage } from 'react-intl'

interface Props extends DrawerProps {
  visible: boolean
  loading?: boolean
  width?: number
  onCancel: Function
  children: React.ReactNode | React.ReactNode[]
}

export default function LayoutDrawer({ width = 500, visible, onCancel, loading = false, children, ...rest }: Props) {
  const [windowWidth] = useWindow()

  let drawlerWidth = width
  if (drawlerWidth > windowWidth) {
    drawlerWidth = windowWidth
  }

  return (
    <Drawer
      {...rest}
      onClose={() => onCancel()}
      visible={visible}
      width={drawlerWidth}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={() => onCancel()} style={{ marginRight: 8 }} icon={<CloseOutlined className="icon-space" />}>
            <FormattedMessage id="close" />
          </Button>
        </div>
      }
    >
      {loading ? <Loading /> : children}
    </Drawer>
  )
}

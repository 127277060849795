import { EditOutlined } from '@ant-design/icons'
import { Table, Tooltip } from 'antd'
import { TableProps } from 'antd/lib/table'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EditableCell, EditableRow } from './parts'
import './styles.scoped.scss'

const EditableTable: React.FC<TableProps<any>> = ({ ...rest }) => {
  let columns = []

  const checkEditable = (columns: any) => {
    return columns.map((col: any) => {
      if (col.children) {
        return {
          ...col,
          children: checkEditable(col.children),
        }
      }

      if (!col.editable) {
        return col
      }

      return {
        ...col,
        title: (
          <div className="editable-cell-header">
            <span>{col.title}</span>
            <Tooltip title={<FormattedMessage id="this_column_is_editable" />}>
              <EditOutlined />
            </Tooltip>
          </div>
        ),
        onCell: (record: any) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: col.save,
        }),
      }
    })
  }

  if (rest.columns) {
    columns = checkEditable(rest.columns)
  }

  if (columns && columns.length > 0) {
    columns.unshift({
      title: '',
      key: 'index',
      width: 30,
      render: (text: any, record: any, index: number) => index + 1,
    })
  }

  return (
    <Table
      {...rest}
      columns={columns}
      components={{
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      }}
      rowClassName={() => 'editable-row'}
    />
  )
}

export default EditableTable

import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import Localization from 'localization'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import reducers from 'redux/reducers'
import sagas from 'redux/sagas'
import MainRouter from 'router'
import 'styles/main.less'
import 'styles/tailwind-custom.css'

import reportWebVitals from './reportWebVitals'

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
const store = createStore(reducers(history), composeWithDevTools(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <MainRouter />
    </Localization>
  </Provider>,
  document.getElementById('root'),
)

export { store, history }
reportWebVitals()

import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import { Button, Layout, Space } from 'antd'
import { LangSelector, ProfileMenu } from 'components'
// import { NotificationMenu } from 'containers'
import { SettingsState } from 'models'
import { useDispatch } from 'react-redux'
import { Actions, DispatchAction } from 'redux/settings/reducers'
import { Actions as UserActions } from 'redux/user/reducers'

import './styles.scoped.scss'

const { Header } = Layout

interface Props {
  settings: SettingsState
}

const TopHeader: React.FC<Props> = ({ settings }) => {
  const dispatch = useDispatch()

  const toggleMenuCollapse = () => {
    let action: DispatchAction = {
      type: Actions.CHANGE_SETTING,
      payload: { setting: 'isMenuCollapsed', value: !settings.isMenuCollapsed },
    }
    dispatch(action)
  }

  const logout = () => {
    let action = {
      type: UserActions.LOGOUT,
    }
    dispatch(action)
  }

  let collapseBtn = null

  collapseBtn = (
    <Button
      icon={settings.isMenuCollapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
      onClick={toggleMenuCollapse}
    />
  )

  return (
    <Header className="header">
      <div>{collapseBtn}</div>
      <div>
        <Space size={[30, 0]} align="center">
          <LangSelector />
          {/* <NotificationMenu /> */}
          <ProfileMenu logout={logout} />
        </Space>
      </div>
    </Header>
  )
}

export default TopHeader

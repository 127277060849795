import { Col, Collapse, Input, Row, Select } from 'antd'
import { FormInstance } from 'antd/lib/form'
import FormItem from 'components/form_item'
import LayoutedForm from 'components/layouted_form'
import { useIntl } from 'react-intl'

interface Props {
  form: FormInstance
  onSearch: Function
}
const PersonFilter: React.FC<Props> = ({ form, onSearch }) => {
  const intl = useIntl()

  return (
    <Collapse defaultActiveKey={['1']}>
      <Collapse.Panel header={intl.formatMessage({ id: 'person_filter' })} key="1">
        <LayoutedForm form={form} initialValues={{ order: 'ascend' }}>
          <Row gutter={[16, 16]} className="filter">
            <Col span={8}>
              <FormItem name="first_name" label="first_name" className="no-margin">
                <Input placeholder="Search" onChange={() => onSearch()} size="small" />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem name="sortField" label="sort" className="no-margin">
                <Select onChange={() => onSearch()} size="small">
                  <Select.Option value="first_name">{intl.formatMessage({ id: 'first_name' })}</Select.Option>
                  <Select.Option value="last_name">{intl.formatMessage({ id: 'last_name' })}</Select.Option>
                  <Select.Option value="nick_name">{intl.formatMessage({ id: 'nick_name' })}</Select.Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem name="sortOrder" label="order" className="no-margin">
                <Select placeholder="order" onChange={() => onSearch()} size="small">
                  <Select.Option value="asc">{intl.formatMessage({ id: 'ascend' })}</Select.Option>
                  <Select.Option value="desc">{intl.formatMessage({ id: 'descend' })}</Select.Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </LayoutedForm>
      </Collapse.Panel>
    </Collapse>
  )
}

export default PersonFilter

const locale = {
  '403': 'Эрх хүрсэнгүй',
  '404': 'Энэхүү хуудас олдсонгүй',
  ok: 'Ок',
  create: 'Үүсгэх',
  cancel: 'Хаах',
  email: 'Email',
  owner: 'Эзэн',
  logout: 'Гарах',
  username: 'Хэрэглэгчийн нэр',
  description: 'Тайлбар',
  desc: 'Тайлбар',
  connect: 'Холбогдох',
  month_1: '1 сар',
  month_2: '2 сар',
  month_3: '3 сар',
  month_4: '4 сар',
  month_5: '5 сар',
  month_6: '6 сар',
  month_7: '7 сар',
  month_8: '8 сар',
  month_9: '9 сар',
  month_10: '10 сар',
  month_11: '11 сар',
  month_12: '12 сар',
  action: 'Үйлдэл',
  actions: 'Үйлдэлүүд',
  duration: 'Үргэлжлэх хугацаа',
  progress: 'Хувь',
  commit: 'Түүх',
  commits: 'Түүхүүд',
  time: 'Цаг',
  no_records: 'Хоосон',
  signin: 'Нэвтрэх',
  signup: 'Бүртгүүлэх',
  status: 'Төлөв',
  team: 'Баг',
  admin: 'Админ',
  team_leader: 'Багын ахлах',
  user: 'Хэрэглэгч',
  role: 'Үүрэг',
  system: 'Систем',
  business: 'Бизнес',
  reset: 'Дахих',
  search: 'Хийх',
  close: 'Хаах',
  submit: 'Бүртгэх',
  this: 'Энэхүү',
  password: 'Нууц үг',
  are_you_sure: 'Та илтгэлтэй байна уу?',
  active: 'Идвэхтэй',
  inactive: 'Идвэхгүй',
  create_success: 'Амжилттай үүссэн',
  modify_success: 'Амжилттай шинжилсэн',
  change_info: 'Ерөнхий мэдээлэл засах',
  delete: 'Устгах',
  edit: 'Засах',
  name: 'Нэр',
  type: 'Төрөл',
  due_date: 'Дуусах огноо',
  color: 'Өнгө',
  not_selected: 'Сонгогдоогүй',
  confirm_delete: 'Та устгахдаа итгэлтэй байна уу? Тэгвэл "{name}" энэхүү нэрийг доорх талбарт бичнэ үү.',
  back_home: 'Нүүр хуудасруу буцах',
  tasks: 'Даалгаварууд',
  projects: 'Төслүүд',
  users: 'Хэрэглэгчид',
  works: 'Модулиуд',
  sort: 'Сорт',
  order: 'Эрэмбэ',
  ascend: 'Ascend',
  descend: 'Descend',
  difficulty: 'Хүнд байдал',
  human_time: 'Хүн цаг',
  work: 'Модуль',
  work_title: 'Ажил',
  low: 'Бага',
  mid: 'Дунд',
  high: 'Өндөр',
  true: 'Тийм',
  false: 'Үгүй',
  by_project: 'Төсөлөөр',
  by_person: 'Хүнээр',
  dashboard: 'Dashboard',
  members: 'Гишүүд',
  set_time_interval: 'Ажлын мөчлөг тохируулах',
  start_to_end: 'Эхлэх болон дуусах огноо',
  create_time_interval: 'Ажлын мөчлөг үүсгэх',
  project_filter: 'Төслийн шүүлтүүр',
  time_interval: 'Ажлын мөчлөг',
  project: 'Төсөл',
  in_work: 'Модуль дотор байгаа эсэх?',
  assignee: 'Гүйцэтгэх ажилтан',
  add_and_close: 'Нэмэх & Хаах',
  add_and_next: 'Нэмэх & Дараагых',
  allocated_time: 'Хуваарилсан цаг',
  from_old_task: 'Дуусаагүй даалгавараас',
  select_old_task: 'Даалгавар сонгох',
  remark: 'Remark',
  deliverable: 'Deliverable',
  completion: 'Хувь',
  claimed_time: 'Ажилласан цаг',
  add_user: 'Хэрэглэгч нэмэх',
  first_name: 'Өөрийн нэр',
  last_name: 'Овог нэр',
  fullname: 'Бүтэн нэр',
  nick_name: 'Хоч нэр',
  last_login_date: 'Сүүлд нэвтэрсэн',
  registered: 'Бүртгэсэн',
  set_password: 'Нууц үг тохируулах',
  change_role: 'Үүрэг өөрчлөх',
  change_team: 'Баг өөрчлөх',
  is_active: 'Ивэхтэй эсэх',
  confirm_password: 'Нууг үг баталгаажуулах',
  create_user: 'Хэрэглэгч үүсгэх',
  create_team: 'Баг үүсгэх',
  add_team: 'Баг нэмэх',
  change_leader: 'Багын удирдагч солих',
  create_create: 'Төсөл үүсгэх',
  contract_status: 'Гэрээний төлөв',
  project_assistant: 'Төслийн туслах',
  personality: 'Хувь чанар',
  change_assistant: 'Төслийн туслах солих',
  change_owner: 'Эзэн солих',
  change_member: 'Гишүүд өөрчлөх',
  add_member: 'Багын гишүүн нэмэх',
  commit_history: 'Түүх',
  add: 'Даалгавар нэмэх',
  history: 'Түүх',
  profile: 'Хувийн булан',
  task: 'Даалгавар',
  report: 'Тайлан',
  add_work: 'Модуль нэмэх',
  select: 'Сонгох',
  clear_search: 'Хайлт цэвэрлэх',
  calc_progress: 'Хувийг автоматаар бодох',
  change_progress: 'Хувь өөрчлөх',
  created_date: 'Үүссэн огноо',
  modified_date: 'Өөрчилсөн огноо',
  delete_success: 'Амжилттай устгалаа',
  teams: 'Багууд',
  // Validation for form field
  required_field: '{name} талбар шаардлагатай',
  not_match: 'Баталгаажуулах нэр таарсангүй!',
  please_confirm: 'Баталгаажуулалт хийх шаардлагатай!',
  // Team
  leader: 'Удирдагч',
  team_filter: 'Баг шүүлтүүр',
  // Project
  last_interval_progress: 'Сүүлийн мөчлөгийн хувь',
  add_project: 'Төсөл нэмэх',
  assistant: 'Туслах',
  project_type_1: 'Хөгжүүлэлт',
  project_type_2: 'Зөвлөгч',
  project_type_3: 'Бизнес (Түр зуурын)',
  project_type_4: 'Бизнес (Байнгын)',
  project_type_5: 'Байнгын',
  project_contract_status_1: 'Гэрээгүй',
  project_contract_status_2: 'Гэрээ хийнэ',
  project_contract_status_3: 'Гэрээ хийсэн',
  project_status: 'Төслийн төлөв',
  project_status_1: 'Хийгдэж байгаа',
  project_status_2: 'Хүлээгдэж байгаа',
  project_status_3: 'Дууссан',
  project_status_4: 'Зогсоосон',
  project_status_5: 'Тохиролцож байгаа',
  project_status_6: 'Хугацаа хэтэрсэн',
  project_personality_0: '-',
  project_personality_1: 'Batman',
  project_personality_2: 'Superman',
  project_personality_3: 'Joker',
  project_action_1: 'эзнийг өөрчилсөн',
  project_action_2: 'туслах өөрчилсөн',
  project_action_3: 'Зассан',
  project_action_4: 'мөчлөг өөрчилсөн',
  project_action_5: 'хувь өөрчилсөн',
  project_action_6: 'гишүүн өөрчилсөн',
  // work
  work_action_1: 'Хувь өөрчлөгдсөн',
  work_action_2: 'Зассан',
  work_difficulty_1: 'Бага',
  work_difficulty_2: 'Дунд',
  work_difficulty_3: 'Өндөр',
  // Interval
  intervals: 'Мөчлөгүүд',
  interval: 'Мөчлөг',
  add_interval: 'Мөчлөг нэмэх',
  begin_date: 'Эхлэх огноо',
  end_date: 'Дуусах огноо',
  // task
  add_task: 'Даалгавар нэмэх',
  unhide_100: '100% нуухаа болих',
  hide_100: '100% нуух',
  person_filter: 'Хүнээр хайх',
  project_count: 'Төслийн тоо',
  work_count: 'Модулийн тоо',
  task_count: 'Даалгавар тоо',
  interval_performance: 'Мөчлөгийн гүйцэтгэл',
  last_interval_performance: 'Өмнөх мөчлөгийн гүйцэтгэл',
  last_interval_inherited_task_count: 'Өмнөх мөчлөгөөс удамжсан даалгаварууд',
  total_allocated_time: 'Нийт хийгдэх хугацаа',
  total_claimed_time: 'Нийт хийсэн хугацаа',
  total_completion: 'Дундаж гүйцэтгэл',
  interval_progress: 'Мөчлөг хувь',
  inherited_tasks: 'Удамшсан даалгаварууд',
  this_column_is_editable: 'Энэхүү багана дээр cell-ийн түвшинд өөрчлөх болможтой.',
  place_task_id: 'Даалгаварын ID-г бөглөнө үү',
  task_not_found: 'Даалгавар олдсонгүй',
  task_id: 'Даалгавар ID',
  old_password: 'Хуучин нууц үг',
  new_password: 'Шинэ нууц үг',
  remark_and_deliverable: 'Үрдүн ба Хүртэмж',
  record_and_delivery_type_1: 'Үр дүн',
  record_and_delivery_type_2: 'Хүртээмж',
  not_yet: 'Амжаагүй',
  batman_count: 'Batman тоо',
  superman_count: 'Superman тоо',
  joker_count: 'Joker тоо',
  avg: 'Дундаж',
  last_avg: 'Өмнөх дундаж',
  last_tasks: 'Өмнөх даалагавар тоо',
  MVP: 'MVP',
  updated_count: 'Зассан тоо',
  leaderboard: 'Тэргүүлэгчдийн самбар',
  // leaderboard
  total: 'Нийт',
  finish_by_this_month: 'Энэ сард дуусах',
  all_employees: 'Бүх ажилчид',
  choose_name: 'Нэр сонгох',
  filter_project: 'Төсөл ангилах',
  '3_month': '3 сар',
  '6_month': '6 сар',
  '12_month': '1 жил',
  start_date: 'Эхлэх огноо',
  module: 'Модуль',
  all_projects: 'Бүх төслүүд',
  action_history: 'Үйлдлийн түүх',
  add_module: 'Модуль нэмэх',
  work_status_0: '-',
  work_status_1: 'Эхлээгүй',
  work_status_2: 'Хийж байгаа',
  work_status_3: 'Дууссан',
  work_status_4: 'Шалгах',
  work_status_5: 'Хүлээгдэж байгаа',
  task_statsus_0: '-',
  task_status_1: 'Эхлээгүй',
  task_status_2: 'Хийж байгаа',
  task_status_3: 'Шалгах',
  task_status_4: 'Хүлээгдэж байгаа',
  task_status_5: 'Дууссан',
  only_task_view: 'Зөвхөн даалгавраар харах',
  full_task_view: 'Бүхлээр нь харах',
  copy_unfinished_tasks_desc: 'Бүх ажилчдын өмнөх интервал-аас дуусгаагүй ажлуудыг шинэ интервал-руу хуулах',
  copy_unfinished_tasks: 'Өмнөх интервал-аас ажил хуулах',
  project_details: 'Төслийн дэлгэрэнгүй',
  modules: 'Модулиуд',
  module_name: 'Модулийн нэр',
  more: 'Дэлгэрэнгүй',
  updates: 'Шинэчлэлтүүд',
  'coffee-match': 'Coffee match',
  'coffee-match-teams': 'Coffee match багууд',
  coffee_match: 'Таарал',
  id: 'Дугаар',
  is_met: 'Уулзсан эсэх',
  meet_date: 'Уулзах огноо',
  choose_teams: 'Багууд сонгох',
  coffee_match_frequency: 'Давтамж',
  coffee_match_start_day: 'Уулзах өдөр (7 хоногийн)',
  coffee_match_skip_last_selected_person: 'Сүүлд таарсан хүнийг тааруулахгүй',
  coffee_match_dont_match_selected_pairs: `Уулзсан хүмүүсийг дахиж тааруулахгүй`,
  coffee_match_dont_match_members_of_the_same_team: `Нэг багийн хүмүүсийг хооронд нь тааруулахгүй`,
  coffee_match_is_disabled: `Зогсоосон`,
  coffee_match_is_limit_reached: `Дууссан`,
  daily: 'Өдөр болгон',
  weekly: 'Долоо хоног болгон',
  monthly: 'Сар болгон',
  go_back: 'Буцах',
  badges: 'Badges',
  give_badge: 'Badge өгөх',
  official: 'Байгууллагаас',
  icon_url: 'Badge image url',
  image: 'Зураг',
  default_icon: 'Анхны зураг',
  badge_icon: 'Тэмдэг',
  avatar: 'Аватар',
  category: 'Төрөл',
  mn: 'mn',
  badge_limit: 'Badge хязгаар (Тухайн сард өгч болох badge хязгаар)',
  update: 'Шинэчлэх',
  default: 'Default',
  task_reminder_notification: 'Task сануулга',
  team_leader_reminder: 'Багийн ахлахд мэдэгдэх',
  weekly_underwork_reminder: '7 хоногт бага ажилласан бол админд мэдэгдэх',
  settings: 'Тохиргоо',
  enabled: 'Идэвхжүүлсэн',
  success: 'Амжилттай',
  task_reminder_absent_days: 'Байхгүй байх өдөр',
  task_reminder_first_schedule: 'Эхний цаг',
  task_reminder_second_schedule: 'Хоёрдахь цаг',
  task_reminder_notify_leader_first_schedule: 'Багийн ахлагчид мэдэгдэх эхний цаг',
  task_reminder_notify_leader_second_schedule: 'Багийн ахлагчид мэдэгдэх хоёрдахь цаг',
  task_reminder_user_message: 'Хэрэглэгч дээр очих сануулга',
  task_reminder_leader_message: 'Багийн ахлагч дээр очих сануулга',
  task_reminder_notify_leader_absent_days: 'Байхгүй байх өдөр (Багийн ахлагчид мэдэгдэх)',
}

export default locale

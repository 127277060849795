import { CloseOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import { ModalProps } from 'antd/lib/modal'
import { IconBtn } from 'components'
import LayoutedForm from 'components/layouted_form'
import { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

interface Props extends ModalProps {
  name: string
  visible: boolean
  onClose: Function
  onSubmit: Function
  onRefresh?: Function
}

interface FormValue {
  confirm: string
}
const DeleteModal: React.FC<Props> = ({ name, visible, onClose, onRefresh, onSubmit, ...rest }) => {
  const mounted = useRef<boolean>(false)
  const intl = useIntl()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const handleSubmit = async () => {
    try {
      await form.validateFields()
      setLoading(true)
      await onSubmit()
    } catch (e) {}
    if (mounted.current) setLoading(false)
  }
  return (
    <Modal
      {...rest}
      className={`${rest.className} form_modal`}
      onCancel={() => onClose()}
      visible={visible}
      footer={
        <div className="flex w-full">
          <Button className="ml-auto" onClick={() => onClose()} icon={<CloseOutlined className="icon-space" />}>
            <FormattedMessage id="close" />
          </Button>
          <IconBtn
            className="ml-auto"
            onClick={() => handleSubmit()}
            type="primary"
            danger
            icon={<DeleteOutlined className="icon-space" />}
            loading={loading}
          >
            <FormattedMessage id="delete" />
          </IconBtn>
        </div>
      }
    >
      <LayoutedForm<FormValue> form={form} name="delete_form">
        <p className="my-4">{intl.formatMessage({ id: 'confirm_delete' }, { name })}</p>
        <FormItem
          className="w-full"
          name="confirm"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'please_confirm' }),
            },
            ({ getFieldValue }) => ({
              validator(_: any, value: string) {
                if (!value || value === name) {
                  return Promise.resolve()
                }
                return Promise.reject(intl.formatMessage({ id: 'not_match' }))
              },
            }),
          ]}
        >
          <Input placeholder="confirm your action" />
        </FormItem>
      </LayoutedForm>
    </Modal>
  )
}

export default DeleteModal

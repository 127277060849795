import { Input, Form, InputNumber } from 'antd'
import { EditableContext } from 'hooks'
import React, { useContext, useEffect, useRef, useState } from 'react'

import '../styles.scoped.scss'

interface Props {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode
  dataIndex: string
  record: any
  handleSave: (record: any) => void
}

const EditableCell: React.FC<Props> = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef<any>(null)
  const form = useContext(EditableContext)!

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({ [dataIndex]: record[dataIndex] })
  }

  const save = async () => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  const close = async () => {
    toggleEdit()
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <div className="editable-cell-container">
        <Form.Item
          noStyle
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {typeof record[dataIndex] === 'number' ? (
            <InputNumber ref={inputRef} className="w-fill" size="small" onPressEnter={save} onBlur={close} />
          ) : (
            <Input ref={inputRef} className="w-fill" size="small" onPressEnter={save} onBlur={close} />
          )}
        </Form.Item>
      </div>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

export default EditableCell

import {
  ControlOutlined,
  CheckSquareOutlined,
  FileDoneOutlined,
  DashboardOutlined,
  CoffeeOutlined,
  HeartOutlined,
  PictureOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { ReactComponent as Logo } from 'assets/images/svg/logo.svg'
import { history } from 'index'
import { AppState } from 'models'
import { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import './styles.scoped.scss'

const { Sider } = Layout
const { SubMenu } = Menu

interface MenuItem {
  key: string
  title: string
  icon?: ReactNode
  url?: string
  children?: MenuItem[]
}

const PageMenu: React.FC<{}> = () => {
  const { settings, user } = useSelector<AppState, AppState>((state) => state)
  const location = useLocation()
  const intl = useIntl()
  const menu = [
    {
      key: 'work',
      title: intl.formatMessage({ id: 'work_title' }),
      icon: <CheckSquareOutlined />,
      children: [
        {
          key: 'dashboard',
          url: '/work/dashboard',
          title: intl.formatMessage({ id: 'dashboard' }),
        },
        {
          key: 'view-team-tasks',
          url: '/work/view-team-tasks',
          title: intl.formatMessage({ id: 'team' }),
        },
      ],
    },
  ] as MenuItem[]

  /**
   * RBAC хийх шаардлагагүй гэж үзсэн тул зарим permission-г ингэж шалгаж байгаа
   */
  if (user.role === 'user' && user.is_project_workable) {
    if (menu[0].children)
      menu[0].children.push({
        key: 'works',
        url: '/work/works',
        title: intl.formatMessage({ id: 'works' }),
      })
  }

  if (user.role === 'admin' || user.role === 'team_leader') {
    if (menu[0].children)
      menu[0].children.push(
        {
          key: 'tasks',
          url: '/work/tasks',
          title: intl.formatMessage({ id: 'tasks' }),
        },
        {
          key: 'works',
          url: '/work/works',
          title: intl.formatMessage({ id: 'works' }),
        },
      )
    menu.push({
      key: 'report',
      title: intl.formatMessage({ id: 'report' }),
      icon: <FileDoneOutlined />,
      children: [
        {
          key: 'team',
          url: '/report/team',
          title: intl.formatMessage({ id: 'team' }),
        },
        {
          key: 'project',
          url: '/report/project',
          title: intl.formatMessage({ id: 'project' }),
        },
      ],
    })
  }
  if (user.role === 'admin') {
    menu.push({
      key: 'admin',
      url: '/admin',
      title: intl.formatMessage({ id: 'admin' }),
      icon: <ControlOutlined />,
      children: [
        {
          key: 'users',
          url: '/admin/users',
          title: intl.formatMessage({ id: 'users' }),
        },
        {
          key: 'teams',
          url: '/admin/teams',
          title: intl.formatMessage({ id: 'teams' }),
        },
        {
          key: 'projects',
          url: '/admin/projects',
          title: intl.formatMessage({ id: 'projects' }),
        },
        {
          key: 'intervals',
          url: '/admin/intervals',
          title: intl.formatMessage({ id: 'intervals' }),
        },
      ],
    })
    menu.push({
      key: 'leaderboard',
      url: '/leaderboard',
      title: intl.formatMessage({ id: 'leaderboard' }),
      icon: <DashboardOutlined />,
    })
    menu.push({
      key: 'coffee-match',
      url: '/coffee-match',
      title: intl.formatMessage({ id: 'coffee-match' }),
      icon: <CoffeeOutlined />,
    })
    menu.push({
      key: 'badges',
      url: '/badges',
      title: intl.formatMessage({ id: 'badges' }),
      icon: <HeartOutlined />,
    })
    menu.push({
      key: 'badge-announcement',
      url: '/badge-announcement',
      title: 'Badge announcement',
      icon: <HeartOutlined />,
    })
    menu.push({
      key: 'avatar',
      url: '/avatar',
      title: intl.formatMessage({ id: 'avatar' }),
      icon: <PictureOutlined />,
    })
  }

  const onMenuClick = (url: string) => {
    history.push(url)
  }

  const locationToKey = (pathname: string) => {
    let index = pathname.lastIndexOf('/')
    return pathname.substr(index + 1)
  }

  const renderMenu = (data: MenuItem[]) => {
    return data.map((item: MenuItem) => {
      if (item.children) {
        return (
          <SubMenu key={item.key} icon={item.icon} title={item.title}>
            {renderMenu(item.children)}
          </SubMenu>
        )
      }
      return (
        <Menu.Item key={item.key} icon={item.icon} title={item.title} onClick={() => onMenuClick(item.url || '')}>
          {item.title}
        </Menu.Item>
      )
    })
  }

  const selectedKeys = [locationToKey(location.pathname)]
  return (
    <Sider
      className="sticky top-0 max-h-screen"
      trigger={null}
      collapsible
      breakpoint="lg"
      collapsed={!settings.isMenuCollapsed}
      collapsedWidth={0}
      width={220}
    >
      <div className="logo">
        <Logo height={32} />
      </div>
      <Menu theme="dark" selectedKeys={selectedKeys} mode="inline">
        {renderMenu(menu)}
      </Menu>
    </Sider>
  )
}

export default PageMenu

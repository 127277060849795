import React, { useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import { io } from 'socket.io-client'

interface IBadgeInputs {
  received_user_name: string
  giving_user: string
  avatar_url: string
  badge_name: string
  description: string
  icon_url: string
  message: string
}

const BadgeAnnouncement: React.FC = () => {
  const socketRef = useRef<any>()
  const [data, setData] = useState<Partial<IBadgeInputs>>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    socketRef.current = io('https://api-badge.fibo.cloud')

    socketRef.current.on('your_id', (body: any) => {
      console.log(body)
    })

    socketRef.current.on(
      'badge_announcement',
      (body: {
        received_user_name: string
        giving_user: string
        badge_name: string
        description: string
        icon_url: string
      }) => {
        setData(body)

        setTimeout(() => {
          setLoading(false)
        }, 2000)

        console.log(body)
      },
    )
  }, [])
  return (
    <div
      className="w-full flex flex-col gap-6 items-center justify-center py-10 min-h-screen h-full bg-cover bg-center"
      style={{ background: !data ? 'url(/badge-initial.jpg)' : 'url(/badge-background.jpg)' }}
    >
      {!data && (
        <div className="w-full h-full grid place-content-center gap-20">
          <span className="text-red-600 font-bold text-center" style={{ fontSize: '7vw' }}>
            {' '}
          </span>
          <div className="mx-auto">
            {/* <span
              className="font-bold text-center animate-bounce transition-all text-black"
              style={{ fontSize: '7vw' }}
            >
              Happy new year <span className="text-red-500">2022</span>
            </span> */}
          </div>
        </div>
      )}
      {data && <Confetti numberOfPieces={400} />}
      {data && !loading && (
        <div className="flex flex-col items-center gap-20 h-full w-full">
          {/* <span className='text-4xl font-bold text-gray-500 text-center'>
              <span className='text-gray-500'>{data.giving_user} </span> 
            <span className='text-yellow-400'>{data.received_user_name}</span>-д
          </span> */}

          <div className="flex flex-col md:flex-row gap-30 h-full w-full justify-center items-start">
            <div className="flex flex-col items-center gap-2 w-full">
              <img
                className="rounded-full object-cover"
                src={data.avatar_url}
                alt="internet"
                style={{ width: '50vh', height: '50vh' }}
              />

              <span className="text-white font-bold text-center" style={{ fontSize: '5vw' }}>
                {data.received_user_name}
              </span>
            </div>

            <div className="flex flex-col gap-2 items-center w-full">
              <img
                className="rounded-full object-cover"
                src={data.icon_url}
                alt="internet"
                style={{ width: '50vh', height: '50vh' }}
              />
              <span className="font-bold text-white text-center" style={{ fontSize: '5vw' }}>
                {data.badge_name}
              </span>
              {/* <span className='text-lg font-bold text-gray-500 max-w-xs text-center'>{data.description}</span> */}
            </div>
          </div>
          <div className="mt-auto">
            {/* <span
              className="font-bold text-center animate-bounce transition-all text-white"
              style={{ fontSize: '4vw' }}
            >
              Happy new year <span className="text-red-500">2022</span>
            </span> */}
          </div>
        </div>
      )}
    </div>
  )
}

export default BadgeAnnouncement

import { Col, Row, Typography } from 'antd'
import { Loading } from 'components'
import SummaryTable from 'components/summary_table'
import { UserRecord, UserReportRecord } from 'models'
import { useIntl } from 'react-intl'
import { userToString } from 'utils'

interface Props {
  user: UserRecord
  report?: UserReportRecord
  loading?: boolean
}

const ProjectSummary: React.FC<Props> = ({ user, report, loading = false }) => {
  const intl = useIntl()
  return (
    <div>
      {loading && (
        <div style={{ position: 'relative', minHeight: '100px' }}>
          <Loading />
        </div>
      )}
      {!loading && report && (
        <Row gutter={[0, 0]}>
          <Col sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Typography.Title level={5} className="p-cap">
              {userToString(user)}
            </Typography.Title>
          </Col>
          <Col sm={24} md={24} lg={24} xl={24} xxl={24}>
            <SummaryTable
              data={[
                {
                  key: intl.formatMessage({ id: 'interval_performance' }),
                  val: report.interval_performance,
                  is_prep: true,
                },
                {
                  key: intl.formatMessage({ id: 'project_count' }),
                  val: report.project_count,
                },
                {
                  key: intl.formatMessage({ id: 'work_count' }),
                  val: report.work_count,
                },
                {
                  key: intl.formatMessage({ id: 'task_count' }),
                  val: report.task_count,
                },
                {
                  key: intl.formatMessage({ id: 'last_interval_inherited_task_count' }),
                  val: report.from_previous_task_count,
                },
                {
                  key: intl.formatMessage({ id: 'last_interval_performance' }),
                  val: report.last_interval_performance,
                  is_prep: true,
                },
              ]}
            />
          </Col>
        </Row>
      )}
    </div>
  )
}

export default ProjectSummary

import { CloseOutlined, FormOutlined } from '@ant-design/icons'
import { Button, Drawer } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import { useWindow } from 'hooks'
import { FormattedMessage } from 'react-intl'

interface Props extends DrawerProps {
  visible: boolean
  formName: string
  width?: number
  okText?: string
  loading?: boolean
  onCancel: Function
  children: React.ReactNode | React.ReactNode[]
}

export default function FormDrawer({
  okText = '',
  formName,
  width = 500,
  visible,
  onCancel,
  loading = false,
  ...rest
}: Props) {
  const [windowWidth] = useWindow()

  let drawlerWidth = width
  if (drawlerWidth > windowWidth) {
    drawlerWidth = windowWidth
  }

  return (
    <Drawer
      {...rest}
      onClose={() => onCancel()}
      visible={visible}
      width={drawlerWidth}
      footer={
        rest.footer ? (
          rest.footer
        ) : (
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => onCancel()}
              style={{ marginRight: 8 }}
              icon={<CloseOutlined className="icon-space" />}
            >
              <FormattedMessage id="close" />
            </Button>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              form={formName}
              icon={<FormOutlined className="icon-space" />}
            >
              {okText ? okText : <FormattedMessage id="submit" />}
            </Button>
          </div>
        )
      }
    />
  )
}

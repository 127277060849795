const locale = {
  '403': 'Permission denied',
  '404': 'This page not found',
  ok: 'Ok',
  create: 'Create',
  cancel: 'Cancel',
  email: 'Email',
  owner: 'Owner',
  logout: 'Logout',
  username: 'Username',
  description: 'Description',
  desc: 'Description',
  connect: 'Connect',
  month_1: 'January',
  month_2: 'February',
  month_3: 'March',
  month_4: 'April',
  month_5: 'May',
  month_6: 'June',
  month_7: 'July',
  month_8: 'August',
  month_9: 'September',
  month_10: 'October',
  month_11: 'November',
  month_12: 'December',
  action: 'Action',
  actions: 'Actions',
  duration: 'Duration',
  progress: 'Progress',
  commit: 'Commit',
  commits: 'Commits',
  time: 'Time',
  no_records: 'Empty',
  signin: 'Sign In',
  signup: 'Sign Up',
  status: 'Status',
  team: 'Team',
  admin: 'Admin',
  team_leader: 'Team Leader',
  user: 'User',
  role: 'Role',
  system: 'System',
  business: 'Business',
  reset: 'Reset',
  search: 'Search',
  close: 'Close',
  submit: 'Submit',
  this: 'This',
  password: 'Password',
  are_you_sure: 'Are you sure?',
  active: 'Active',
  inactive: 'In-Active',
  create_success: 'Successfully created',
  modify_success: 'Successfully modified',
  change_info: 'Change Info',
  delete: 'Delete',
  edit: 'Edit',
  name: 'Name',
  type: 'Type',
  due_date: 'Due date',
  color: 'Color',
  confirm_delete: 'Are you sure to delete? Then write this "{name}" below field.',
  back_home: 'Back Home',
  tasks: 'Tasks',
  projects: 'Projects',
  users: 'Users',
  works: 'Modules',
  sort: 'Sort',
  order: 'Order',
  ascend: 'Ascend',
  difficulty: 'Difficulty',
  human_time: 'Man hour',
  work: 'Work',
  work_title: 'Work',
  low: 'Low',
  mid: 'Mid',
  high: 'High',
  true: 'True',
  false: 'Flase',
  descend: 'Descend',
  by_project: 'By project',
  by_person: 'By person',
  dashboard: 'Dashboard',
  members: 'Members',
  set_time_interval: 'Set time interval',
  start_to_end: 'Start to end date',
  create_time_interval: 'Create time interval',
  project_filter: 'Project filter',
  time_interval: 'Time interval',
  project: 'Project',
  in_work: 'In Module?',
  assignee: 'Assignee',
  add_and_close: 'Add & Close',
  add_and_next: 'Add & Next',
  allocated_time: 'Allocated time',
  from_old_task: 'From Old Task',
  select_old_task: 'Select old task',
  remark: 'Remark',
  deliverable: 'Deliverable',
  completion: 'Completion',
  claimed_time: 'Claimed time',
  add_user: 'Add user',
  first_name: 'Firstname',
  last_name: 'Lastname',
  fullname: 'Fullname',
  nick_name: 'Nickname',
  last_login_date: 'Last login',
  registered: 'Registered',
  set_password: 'Set Password',
  change_role: 'Change Role',
  change_team: 'Change Team',
  is_active: 'Is Active',
  confirm_password: 'Confirm password',
  create_user: 'Create User',
  create_team: 'Create Team',
  add_team: 'Add Team',
  change_leader: 'Change Team Leader',
  create_create: 'Create Project',
  contract_status: 'Contract status',
  project_assistant: 'Project assistant',
  personality: 'Personality',
  change_assistant: 'Change assistant',
  change_owner: 'Change Owner',
  change_member: 'Change member',
  add_member: 'Add member',
  commit_history: 'Commit History',
  add: 'Add Task',
  history: 'History',
  profile: 'Profile',
  task: 'Task',
  report: 'Report',
  add_work: 'Add modules',
  select: 'Select',
  clear_search: 'Clear search',
  calc_progress: 'Is auto calc progress',
  change_progress: 'Progress change',
  created_date: 'Created date',
  modified_date: 'Modified date',
  delete_success: 'Deleted successfully',
  teams: 'Teams',
  // Validation for form field
  required_field: '{name} field is required',
  not_match: 'Confirming name not matching!',
  please_confirm: 'Please confirm is required!',
  // Team
  leader: 'Leader',
  team_filter: 'Team filter',
  // Project
  last_interval_progress: 'Last interval progress',
  add_project: 'Add project',
  assistant: 'Assistant',
  project_type_1: 'Developer',
  project_type_2: 'Consulting',
  project_type_3: 'Business temporary',
  project_type_4: 'Buiness permanent',
  project_type_5: 'Operational',
  project_contract_status_1: 'No contract',
  project_contract_status_2: 'Contract waiting',
  project_contract_status_3: 'Contract done',
  project_status: 'Project status',
  project_status_1: 'Ongoing',
  project_status_2: 'On hold',
  project_status_3: 'Done',
  project_status_4: 'Cancelled',
  project_status_5: 'Negotiating',
  project_status_6: 'Overdue',
  project_personality_0: '-',
  project_personality_1: 'Batman',
  project_personality_2: 'Superman',
  project_personality_3: 'Joker',
  project_action_1: 'changed owner',
  project_action_2: 'changed assistant',
  project_action_3: 'edited',
  project_action_4: 'Time interval change',
  project_action_5: 'changed progress',
  project_action_6: 'changed members',
  // work
  work_action_1: 'Change progress',
  work_action_2: 'Edit',
  work_difficulty_1: 'Low',
  work_difficulty_2: 'Mid',
  work_difficulty_3: 'High',
  // Interval
  intervals: 'Intervals',
  interval: 'Interval',
  add_interval: 'Add interval',
  begin_date: 'Begin date',
  end_date: 'End date',
  // task
  add_task: 'Add task',
  unhide_100: 'Unhide 100%',
  hide_100: 'Hide 100%',
  person_filter: 'Person filter',
  project_count: 'Project count',
  work_count: 'Module count',
  task_count: 'Task count',
  interval_performance: 'Interval performance',
  last_interval_performance: 'Last interval performance',
  last_interval_inherited_task_count: 'Last interval inherited tasks',
  total_allocated_time: 'Total allocated time',
  total_claimed_time: 'Total claimed time',
  total_completion: 'Total completion',
  interval_progress: 'Interval progress',
  inherited_tasks: 'Inherited tasks',
  this_column_is_editable: 'This column is editable',
  place_task_id: 'Place task id',
  task_not_found: 'Task not found',
  task_id: 'Task ID',
  old_password: 'Old password',
  new_password: 'New password',
  remark_and_deliverable: 'Remark & Deliverable',
  record_and_delivery_type_1: 'Remark',
  record_and_delivery_type_2: 'Deliverable',
  not_yet: 'Not yet',
  only_task_view: 'Only task view',
  full_task_view: 'Full task view',
  batman_count: 'Batman count',
  superman_count: 'Superman count',
  joker_count: 'Joker count',
  avg: 'AVG',
  last_avg: 'Last AVG',
  last_tasks: 'Last task count',
  MVP: 'MVP',
  updated_count: 'Updated count',
  leaderboard: 'Leaderboard',
  // leaderboard
  total: 'Total',
  finish_by_this_month: 'Finish by this month',
  all_employees: 'All Employees',
  choose_name: 'Choose Name',
  filter_project: 'Filter project',
  '3_month': '3 Month',
  '6_month': '6 Month',
  '12_month': '1 Year',
  start_date: 'Start date',
  module: 'Module',
  all_projects: 'All projects',
  action_history: 'Action history',
  add_module: 'Add Module',
  //
  work_status_0: '-',
  work_status_1: 'Not started',
  work_status_2: 'On going',
  work_status_3: 'Check',
  work_status_4: 'On hold',
  work_status_5: 'Done',
  //
  task_statsus_0: '-',
  task_status_1: 'Not started',
  task_status_2: 'On going',
  task_status_3: 'Check',
  task_status_4: 'On hold',
  task_status_5: 'Done',
  copy_unfinished_tasks_desc: 'Copy all employees unfinished tasks from previous interval to new interval',
  copy_unfinished_tasks: 'Copy unfinished tasks',
  project_details: 'Project Details',
  modules: 'Modules',
  module_name: 'Module Name',
  more: 'More',
  updates: 'Updates',
  'coffee-match': 'Coffee match',
  'coffee-match-teams': 'Coffee match teams',
  coffee_match: 'Coffee match',
  id: 'ID',
  is_met: 'Is met',
  meet_date: 'Meet date',
  choose_teams: 'Choose teams',
  coffee_match_frequency: 'Frequency',
  coffee_match_start_day: 'Start day of the week',
  coffee_match_skip_last_selected_person: 'Skip last selected person',
  coffee_match_dont_match_selected_pairs: `Don't match selected pairs`,
  coffee_match_dont_match_members_of_the_same_team: `Don't match members of the same team`,
  coffee_match_is_disabled: `Is disabled`,
  coffee_match_is_limit_reached: `Finished`,
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  go_back: 'Go back',
  badges: 'Badges',
  give_badge: 'Give a badge',
  official: 'Official',
  icon_url: 'Badge image url',
  image: 'Image',
  default_icon: 'Default icon',
  badge_icon: 'Badge icon',
  avatar: 'Avatar',
  category: 'Category',
  mn: 'mn',
  badge_limit: 'Badge limit (Number of times a user can give a badge in a month)',
  update: 'Update',
  default: 'Default',
  task_reminder_notification: 'Task reminder notification',
  team_leader_reminder: 'Team leader reminder',
  weekly_underwork_reminder: 'Weekly underwork reminder (for admin)',
  settings: 'Settings',
  enabled: 'Enabled',
  success: 'Success',
  task_reminder_absent_days: 'Absent days',
  task_reminder_first_schedule: 'First schedule',
  task_reminder_second_schedule: 'Second schedule',
  task_reminder_notify_leader_first_schedule: 'Notify leader first schedule',
  task_reminder_notify_leader_second_schedule: 'Notify leader second schedule',
  task_reminder_user_message: 'User message',
  task_reminder_leader_message: 'Leader message',
  task_reminder_notify_leader_absent_days: 'Notify leader absent days',
}

export default locale

import React from 'react'

interface IProps {
  className?: string
  fill?: boolean
  animate?: boolean
  color?: Color
  size?: Size
}

type Color = keyof typeof Colors
type Size = keyof typeof Sizes

enum Colors {
  primary = 'text-primary',
  green = 'text-green-500',
  red = 'text-red-500',
  blue = 'text-blue-500',
  yellow = 'text-yellow-500',
}

enum Sizes {
  small = 'w-5 h-5',
  medium = 'w-7 h-7',
  large = 'w-10 h-10',
}

const Loading: React.FC<IProps> = ({
  className = '',
  fill = false,
  animate = true,
  color = 'primary',
  size = 'medium',
}) => (
  <div className={`${fill ? 'w-full h-full grid place-content-center' : ''}`}>
    <svg
      className={`${className} ${Sizes[size]} ${Colors[color]} ${animate ? 'animate-spin' : ''}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  </div>
)

export default Loading

import { ReactComponent as MnFlag } from 'assets/images/flags/mn.svg'
import { ReactComponent as UsFlag } from 'assets/images/flags/us.svg'
import { AppState, LocaleTypes } from 'models'
import { useDispatch, useSelector } from 'react-redux'
import { Actions, DispatchAction } from 'redux/settings/reducers'

import './styles.scoped.scss'

interface Props {}
const LangSelector: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const locale = useSelector<AppState, string>((state) => state.settings.locale) as LocaleTypes

  const onToggleLangauge = () => {
    let action: DispatchAction = {
      type: Actions.CHANGE_SETTING,
      payload: { setting: 'locale', value: locale === 'en-US' ? 'mn-MN' : 'en-US' },
    }
    dispatch(action)
  }
  return (
    <div className="container" role="button" onClick={onToggleLangauge}>
      {locale === 'en-US' ? <UsFlag width="25" /> : <MnFlag width="25" />}
    </div>
  )
}

export default LangSelector

import { Form } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import { useIntl } from 'react-intl'

interface Props extends FormItemProps {
  label?: string
  required?: boolean
  rules?: any[]
}

const FormItem: React.FC<Props> = ({ label, required = false, rules = [], ...rest }) => {
  const intl = useIntl()

  if (required) {
    rules.push({
      required: true,
      message: intl.formatMessage(
        { id: 'required_field' },
        { name: label ? intl.formatMessage({ id: label }) : intl.formatMessage({ id: 'this' }) },
      ),
    })
  }
  return (
    <Form.Item
      {...rest}
      label={label ? intl.formatMessage({ id: label }) : null}
      rules={rules}
      className={`${rest.className} hide-requered-before`}
    />
  )
}

export default FormItem

import { logoutAPI, getCurrentUser, loginAPI } from 'api'

export const login = async (email: string, password: string) => {
  const response = await loginAPI({ email, password })
  if (response) {
    localStorage.setItem('token', response['token'])
    return true
  }
  return false
}

export const getAuthUser = async () => {
  const token = localStorage.getItem('token')
  if (token) {
    const response = await getCurrentUser()
    if (response) {
      return response
    }
  }
  return null
}

export async function logout() {
  await logoutAPI()
  return true
}

import { ConfigProvider, Empty } from 'antd'
import antdEN from 'antd/lib/locale/en_US'
import antdMN from 'antd/lib/locale/mn_MN'
import dayjs from 'dayjs'
import { AppState, LocaleTypes } from 'models'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'

import messages_en from './locales/en'
import messages_mn from './locales/mn'

const locales = {
  'mn-MN': messages_mn,
  'en-US': messages_en,
}

const antdData = {
  'mn-MN': antdMN,
  'en-US': antdEN,
}

interface Props {
  children: React.ReactNode
}

const Localization: React.FC<Props> = ({ children }) => {
  const locale = useSelector<AppState, string>((state) => state.settings.locale) as LocaleTypes

  dayjs.locale(locale.substring(0, 2))
  return (
    <ConfigProvider locale={antdData[locale]} renderEmpty={() => <Empty />}>
      <IntlProvider locale={locale.substring(0, 2)} messages={locales[locale]} defaultLocale="en">
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}
export default Localization

import { CloseOutlined, FormOutlined } from '@ant-design/icons'
import { Button, Modal, Popconfirm } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { FormattedMessage, useIntl } from 'react-intl'

interface Props extends ModalProps {
  visible: boolean
  formName: string
  width?: number
  okText?: string
  loading?: boolean
  haveConfirm?: boolean
  onSubmit?: Function
  onClose: Function
  children: React.ReactNode | React.ReactNode[]
}

export default function FormModal({
  okText = '',
  formName,
  visible,
  onClose,
  onSubmit,
  loading = false,
  haveConfirm = false,
  ...rest
}: Props) {
  const intl = useIntl()

  let submitButton = (
    <Button
      loading={loading}
      type="primary"
      htmlType="submit"
      form={formName}
      onClick={() => {
        if (onSubmit) onSubmit()
      }}
      icon={<FormOutlined className="icon-space" />}
    >
      {okText ? okText : <FormattedMessage id="submit" />}
    </Button>
  )

  if (haveConfirm) {
    submitButton = (
      <Popconfirm
        title={intl.formatMessage({ id: 'are_you_sure' })}
        okButtonProps={{
          type: 'primary',
          htmlType: 'submit',
          form: formName,
          onClick: () => {
            if (onSubmit) onSubmit()
          },
        }}
      >
        <Button type="primary" loading={loading} icon={<FormOutlined className="icon-space" />}>
          {okText ? okText : <FormattedMessage id="submit" />}
        </Button>
      </Popconfirm>
    )
  }

  return (
    <Modal
      {...rest}
      className={`${rest.className} form_modal`}
      onCancel={() => onClose()}
      visible={visible}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={() => onClose()} style={{ marginRight: 8 }} icon={<CloseOutlined className="icon-space" />}>
            <FormattedMessage id="close" />
          </Button>
          {submitButton}
        </div>
      }
    />
  )
}
